.sessionsSection {
  margin-top: 8px;
}

.sectionTitle {
  font-size: 15px;
  font-weight: 600;
  font-family: 'GT-America-Extended-Bold', sans-serif;
  color: #ffffff;
  margin: 0 0 14px 0;
  letter-spacing: -0.01em;
  padding-left: 2px;
}

.sessionList {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

@media (max-width: 768px) {
  /* Add some space at the top for fixed header on mobile */
  .sessionsSection {
    padding-top: 52px;
  }
} 