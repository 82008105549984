.switcherContainer {
  position: relative;
  width: 180px;
  min-width: 150px;
  height: 30px;
  display: flex;
  justify-content: center;
  margin: 0;
}

.switcherTrack {
  position: relative;
  display: flex;
  background: rgba(18, 18, 18, 0.4);
  border: 1px solid rgba(255, 255, 255, 0.06);
  border-radius: 4px;
  width: 100%;
  overflow: hidden;
  height: 100%;
}

.activeIndicator {
  position: absolute;
  height: calc(100% - 2px);
  background: rgba(45, 45, 45, 0.65);
  border-radius: 3px;
  top: 1px;
  left: 1px;
  transition: transform 0.25s cubic-bezier(0.33, 1, 0.68, 1);
  z-index: 1;
}

.switcherButton {
  flex: 1;
  background-color: transparent !important;
  border: none;
  color: rgba(255, 255, 255, 0.45);
  font-size: 12px;
  font-weight: 400;
  font-family: "GT-America-Standard", sans-serif !important;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0;
  z-index: 2;
  transition: color 0.2s ease;
  padding: 0;
  margin: 0;
  height: 100%;
  line-height: 1;
}

.switcherButton:focus {
  outline: none;
}

.switcherButton:hover {
  color: rgba(255, 255, 255, 1);
  background-color: transparent !important;
}

.switcherButton.active {
  color: white;
  background-color: transparent !important;
}

/* Mobile-specific styles */
@media (max-width: 900px) {
  .switcherContainer {
    height: 34px;
    width: auto;
    flex: 1;
  }
  
  .switcherButton {
    font-size: 13px;
  }
} 