.container {
  margin-bottom: 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.title {
  font-size: 14px;
  font-weight: 500;
  color: #FFFFFF;
  margin: 0;
}

.scrollableContainer {
  position: relative;
  display: flex;
  align-items: center;
}

.scrollContainer {
  overflow-x: auto;
  -ms-overflow-style: none;  /* Hide scrollbar for IE and Edge */
  scrollbar-width: none;  /* Hide scrollbar for Firefox */
  width: 100%;
  padding: 4px 0;
}

.scrollContainer::-webkit-scrollbar {
  display: none;  /* Hide scrollbar for Chrome, Safari and Opera */
}

.avatarList {
  display: flex;
  gap: 16px;
  padding: 4px 0;
}

.avatarWrapper {
  cursor: pointer;
  transition: transform 0.2s ease;
  position: relative;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 48px;
}

.avatarWrapper:hover {
  transform: scale(1.05);
}

.initialsAvatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #5440F0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 500;
  font-size: 16px;
}

.partnerName {
  font-size: 11px;
  color: rgba(255, 255, 255, 0.8);
  margin-top: 4px;
  text-align: center;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Add a subtle animation when clicking an avatar */
.avatarWrapper:active {
  transform: scale(0.95);
}

/* Scroll arrows */
.scrollArrow {
  position: absolute;
  z-index: 2;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: rgba(22, 22, 22, 0.85);
  border: 1px solid rgba(180, 180, 180, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: rgba(210, 210, 210, 0.9);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;
  opacity: 0.92;
  backdrop-filter: blur(2px);
  padding: 0;
}

.scrollArrow:hover {
  background-color: rgba(28, 28, 28, 0.95);
  color: rgba(240, 240, 240, 1);
  border-color: rgba(200, 200, 200, 0.35);
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.15);
  opacity: 1;
  transform: scale(1.05);
}

.scrollArrow:active {
  transform: scale(0.95);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
}

.leftArrow {
  left: -5px;
}

.rightArrow {
  right: -5px;
}

/* Fade effect for scroll edges */
.scrollContainer::before,
.scrollContainer::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 20px;
  pointer-events: none;
  z-index: 1;
}

.scrollContainer::before {
  left: 0;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.8), transparent);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.scrollContainer::after {
  right: 0;
  background: linear-gradient(to left, rgba(0, 0, 0, 0.8), transparent);
  opacity: 0;
  transition: opacity 0.3s ease;
}

/* Show fade effect when arrows are visible */
.scrollContainer.showLeftFade::before {
  opacity: 1;
}

.scrollContainer.showRightFade::after {
  opacity: 1;
} 