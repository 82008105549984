:root {
  --background-primary: #111213;
  --background-secondary: #1a1b1e;
  --border-color: #2e3036;
}

/* Base skeleton animation */
@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  background-color: var(--background-primary);
  border-bottom: 1px solid var(--border-color);
  height: 60px;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 10;
}

.profileSection {
  display: flex;
  align-items: center;
  min-width: 0;
  max-width: calc(100% - 50px);
  overflow: hidden;
  flex: 1;
}

.profileImageSkeleton {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  flex-shrink: 0;
  margin-right: 12px;
  background: linear-gradient(90deg, #232427 25%, #2c2d31 50%, #232427 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}

.profileNameSkeleton {
  height: 16px;
  width: 120px;
  margin: 0;
  border-radius: 4px;
  background: linear-gradient(90deg, #232427 25%, #2c2d31 50%, #232427 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}

.infoButtonSkeleton {
  width: 32px;
  height: 32px;
  border-radius: 4px;
  flex-shrink: 0;
  margin-left: 8px;
  background: linear-gradient(90deg, #232427 25%, #2c2d31 50%, #232427 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}

@media (max-width: 768px) {
  .header {
    padding: 10px 12px;
    height: 52px;
    position: fixed;
    top: 74px; /* Height of the main header */
    left: 0;
    right: 0;
    z-index: 11;
  }
  
  .profileImageSkeleton {
    width: 28px;
    height: 28px;
  }
  
  .profileNameSkeleton {
    height: 14px;
    width: 100px;
  }
} 