.card {
  background-color: #1e1f23;
  border-radius: 10px;
  padding: 16px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: all 0.2s ease;
  border: 1px solid rgba(255, 255, 255, 0.06);
}

.card:hover {
  border-color: rgba(255, 255, 255, 0.15);
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.card.expanded {
  background-color: #23252a;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.status {
  display: flex;
  align-items: center;
  gap: 6px;
}

.statusDot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
}

.statusText {
  font-size: 13px;
  font-weight: 500;
  color: #a9adc1;
  font-family: 'GT-America-Standard', sans-serif;
}

.date {
  font-size: 12px;
  color: #8a8d98;
  font-family: 'GT-America-Standard', sans-serif;
}

/* Collapsed card styles */
.summary {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.descriptionPreview {
  font-size: 14px;
  color: #ffffff;
  margin: 0;
  line-height: 1.4;
  font-family: 'GT-America-Standard', sans-serif;
  flex: 1;
}

.ratingIndicator {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 3px 8px;
  background-color: rgba(65, 56, 32, 0.9);
  border-radius: 6px;
  margin-left: 12px;
  flex-shrink: 0;
}

.star {
  color: #FBC95C;
  font-size: 13px;
}

/* Expanded card styles */
.details {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid rgba(255, 255, 255, 0.06);
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.expandedContent {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.description {
  font-size: 14px;
  color: #a9adc1;
  margin: 0;
  line-height: 1.5;
  font-family: 'GT-America-Standard', sans-serif;
}

.priceRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.price {
  font-size: 14px;
  color: #15B881;
  font-weight: 500;
  font-family: 'GT-America-Standard', sans-serif;
}

.reviewBadge {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 3px 8px;
  background-color: rgba(65, 56, 32, 0.9);
  border-radius: 6px;
  font-size: 13px;
  color: #ffffff;
  font-family: 'GT-America-Standard', sans-serif;
}

.viewDetailsButton {
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: #ffffff;
  padding: 8px 12px;
  border-radius: 6px;
  font-size: 13px;
  font-family: 'GT-America-Standard', sans-serif;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-top: 2px;
  text-align: center;
}

.viewDetailsButton:hover {
  background-color: rgba(255, 255, 255, 0.05);
  border-color: rgba(255, 255, 255, 0.2);
} 