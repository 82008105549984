.headerContainer{
	width: 80%;
	display: flex;
	align-items: center;
}

.backButton{
	margin-right: 15px;
}

.convoWithTitle{
	cursor: pointer;
	text-decoration: underline;
}

.convoMenteeWith{
	cursor: pointer;
}

.sessionContainer {
	width: 100%;
}

.contentContainer {
	position: relative;
	width: 100%;
	padding: 72px 0px 0px 72px;
	display: flex;
	overflow: hidden;
	height: 100vh; /* Take full remaining height */
}

.sidebar{
	flex: 0 0 30%;
    padding: 20px 10px 20px 0;
    overflow-y: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.chatContainer {
	flex: 1;
	display: flex;
	flex-direction: column;
	height: 100%;
	overflow: hidden;
	position: relative;
	border-left: 1px solid rgba(255, 255, 255, 0.2);
}

.chatBox {
	flex: 1;
	display: flex;
	flex-direction: column;
	overflow: hidden;
}

.messages {
	flex: 1;
	overflow-y: auto;
	padding: 20px;
	display: flex;
	flex-direction: column;
	gap: 16px;
	width: 100%;
}

.inputContainer {
    display: flex;
    gap: 5px;
    align-items: center;
    border-top: 1px solid #525252;
    padding: 10px 15px;
    background: black;
    
    /* Instead of fixed positioning, use flex to keep inside chatContainer */
    position: relative; 
    width: 100%; /* Ensures it fits within chatContainer */
}

.inputWrapper {
    width: 100%;
    background: black;
}

/* Apply fixed positioning only for mobile */
@media (max-width: 900px) {
	.chatContainer{
		border-left: none;
	}
    .inputWrapper {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 10;
    }
    .inputContainer {
        position: relative;
        width: 100%;
    }
	.sidebar{
		flex-grow: 1;
        padding-bottom: 80px; /* Give space for the footer on mobile */
	}
    .sidebarFooter {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        background: rgba(0, 0, 0, 0.95);
        padding: 10px 16px;
        z-index: 9;
        border-top: 1px solid rgba(255, 255, 255, 0.1);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 12px;
    }
    
    .sidebarFooter > div {
        margin: 0;
    }
    
    .sidebarFooter :global(.dropdownButton) {
        padding: 8px 12px !important;
    }
    
	.messagingSettings {
        margin-bottom: 0;
        padding: 6px 10px;
    }
	.chatBox{
		padding-bottom: 90px; /* Increased to accommodate both input and notification */
		padding-top: 52px; /* Account for the fixed minimal header */
	}
	.contentContainer {
		padding-top: 126px; /* 74px (main header) + 52px (minimal header) */
	}
}

.inputBox{
	width: 100%;
	border-radius: 40px !important;
	margin: 0px !important;
}

.sendButton{
	background-color: #5440F0;
	border-radius: 50px;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Tabs */
.tabs {
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;
	padding-left: 0; /* Remove left padding to align with header */
}

.tab {
	flex: 1;
	text-align: center;
	padding: 10px 0;
	cursor: pointer;
	color: #FFFFFF;
	border-bottom: 2px solid transparent;
	transition: border-bottom 0.3s ease;
}

.tab.active {
	border-bottom: 2px solid #FFFFFF; /* Underline for active tab */
}

/* Mentor Card */
.convoCard {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 12px 16px;
	border-radius: 8px;
	cursor: pointer;
	margin-bottom: 8px;
	transition: background-color 0.15s ease;
}

.convoCard:hover {
	background-color: rgba(255, 255, 255, 0.05);
}

.convoCard.hovered {
	background-color: rgba(255, 255, 255, 0.05);
}

.convoCard.active {
	background-color: rgba(255, 255, 255, 0.1);
}

.convoCardHeader {
	display: flex;
	align-items: center;
	gap: 12px;	
    width: 100%;
}

.convoCardHeader > div:nth-child(2) {
    flex-grow: 1;
    min-width: 0; /* Allows text to truncate properly */
}

.convoCardHeader > div:last-child {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    min-width: fit-content; /* Prevents timestamp from wrapping */
}

.convoTitle {
	text-align: left;
	font-weight: 500;
	margin-bottom: 4px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 100%;
}

.senderMessage {
	color: #bfbfbf;
	font-size: 14px;
	margin: 0;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 100%;
}

.avatar {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	flex-shrink: 0; /* Prevents avatar from shrinking */
}

.timestamp {
	font-size: 12px;
	color: #999999;
	margin-bottom: 4px;
}

.goToProfileButton{
	display: flex;
	align-items: center;
	gap: 5px;
	background-color: #25282A;
	font-size: 12px;
	color: #FFFFFF;
	padding: 5px 10px;
	border-radius: 15px;
	cursor: pointer;
}

.sender {
	color: #FFFFFF;
	font-size: 16px;
	font-weight: normal;
	margin: 0;
	padding: 0px !important;
}

.userInfo {
	display: flex;
	flex-direction: column;
	margin-top: 5px;
	width: 100%;
}

.messageBlock.user .userInfo {
	align-items: flex-end;
}

.messageBlock.other .userInfo {
	align-items: flex-start;
}

.userNameInfo {
	display: flex;
	align-items: center;
	gap: 5px;
}

.senderName {
	font-size: 12px;
	color: #bfbfbf;
}

.senderAvatar {
	width: 15px;
	height: 15px;
	border-radius: 50%;
}

.unreadBadge {
	display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #5440F0;
    color: #FFFFFF;
    border-radius: 12px;
    padding: 5px;
    font-size: 10px;
    text-align: center;
    font-weight: bold;
}
  
.unreadBadge span {
	display: block;
	font-size: 9px;
}

.timestampBadge{
	font-size: 11px;
	color: #bfbfbf;
}

.toggleButton {
	display: none;
	background-color: #5440F0;
	color: #FFFFFF;
	border: none;
	padding: 10px;
	border-radius: 5px;
	cursor: pointer;
	margin-bottom: 10px;
}

.settingsButton{
	cursor: pointer;
}

.lockedMessage{    
	padding: 10px;
	margin: 0 20px 20px 20px;
	color: white;
	background: #5440F0;
	border-radius: 5px;
	cursor: pointer;
	font-family: 'GT America Extended Bold', sans-serif;
	text-align: center;
}

/* Subtle message limit notification */
.subtleMessageLimit {
    text-align: center;
    color: white;
    font-size: 12px;
    padding: 8px 12px;
    margin-top: 0;
    opacity: 0.8;
    background-color: black;
    width: 100%;
}

/* Apply fixed positioning for mobile */
@media (max-width: 900px) {
    .subtleMessageLimit {
        position: relative;
        width: 100%;
    }
}

.messagingSettings{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 0;
    gap: 8px;
    color: #777777;
    font-size: 12px;
    cursor: pointer;
    transition: color 0.2s ease;
    padding: 6px 8px;
    border-radius: 4px;
    white-space: nowrap;
}

.messagingSettings img {
    opacity: 0.7;
    transition: opacity 0.15s ease;
}

.messagingSettings:hover {
    color: #FFFFFF;
    background-color: transparent;
}

.messagingSettings:hover img {
    opacity: 1;
}

.messagingSettingsText {
    padding-bottom: 0px;
    transition: border-color 0.15s ease;
}

.messagingSettings:hover .messagingSettingsText {
    border-color: transparent;
}

/* Splash pages for empty states */
.emptySplashContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding: 0;
    color: #FFFFFF;
}

.emptySplashContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 24px;
}

.emptySplashGraphic {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    position: relative;
}

.emptySplashGraphic svg {
    filter: drop-shadow(0 0 1px rgba(84, 64, 240, 0.2));
}

.emptySplashTextContent {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.emptySplashTitle {
    font-size: 15px;
    font-weight: 600;
    letter-spacing: -0.01em;
    margin: 0 0 6px 0;
    color: rgba(255, 255, 255, 0.95);
}

.emptySplashText {
    font-size: 13px;
    line-height: 1.4;
    letter-spacing: -0.01em;
    color: rgba(255, 255, 255, 0.6);
    margin: 0;
    max-width: 240px;
}

.emptySplashActionButton {
    background-color: rgba(84, 64, 240, 0.1);
    color: #5440F0;
    padding: 8px 14px;
    border-radius: 6px;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: -0.01em;
    cursor: pointer;
    transition: all 0.15s ease;
    border: none;
    outline: none;
}

.emptySplashActionButton:hover {
    background-color: rgba(84, 64, 240, 0.15);
}

.emptySplashActionButton:active {
    background-color: rgba(84, 64, 240, 0.2);
    transform: scale(0.98);
}

@media (max-width: 900px) {
	.contentContainer {
		flex-direction: column;
        padding: 0px;
        margin-top: 72px;
		height: calc(100vh - var(--header-height, 60px));
	}

	.toggleButton {
		display: block;
	}

	.messagingSettings {
        margin-bottom: 0;
        padding: 6px 10px;
    }
}

.messageContainer {
	display: flex;
	flex-direction: column;
	margin-bottom: 10px;
}

.messageBlock {
	display: flex;
	flex-direction: column;
	max-width: 80%;
	margin-bottom: 25px;
	width: 100%;
}

.messageBlock.user {
    align-self: flex-end;
    align-items: flex-end;
}

.messageBlock.other {
	align-self: flex-start;
	align-items: flex-start;
}

.message {
	padding: 10px 15px;
	border-radius: 20px;
	margin-bottom: 7px;
	background-color: #25282A;
	color: white;
	text-align: left;
	word-wrap: break-word;
	width: fit-content;
	max-width: 100%;
	font-size: 14px;
	line-height: 1.4;
}

.message:last-child {
	margin-bottom: 0;
}

.message.user {
	background-color: #5440F0;
	color: white;
	align-self: flex-end;
}

.message.other {
	align-self: flex-start;
}

.deleteButton {
  background-color: transparent;
  color: #999;
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 18px;
  line-height: 1;
  cursor: pointer;
  margin-left: 8px;
  transition: opacity 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  opacity: 0.6;
}

.deleteButton:hover {
  opacity: 1;
}

.conversationsList {
    flex: 1;
    overflow-y: auto;
    padding-right: 10px;
    margin-right: -10px;
}

.sidebarFooter {
    margin-top: auto;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding: 12px 10px 12px 0;
    margin-bottom: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
}

.sidebarFooter > div {
    margin: 0;
}

.errorNotification {
  background-color: rgba(255, 76, 76, 0.1);
  border: 1px solid rgba(255, 76, 76, 0.3);
  color: #ff4c4c;
  padding: 10px 15px;
  border-radius: 4px;
  margin: 8px 15px;
  font-size: 14px;
  text-align: center;
  animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(-10px); }
  to { opacity: 1; transform: translateY(0); }
}

/* Mobile adjustments for error notification */
@media (max-width: 900px) {
  .errorNotification {
    margin: 0 15px 8px;
  }
}