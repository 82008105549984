:root {
  --background-primary: #111213;
  --background-secondary: #1a1b1e;
  --text-primary: #ffffff;
  --text-secondary: #a9adc1;
  --text-tertiary: #6e7891;
  --border-color: #2e3036;
  --panel-width: 380px;
  --header-height: 60px;
  --animation-duration: 0.2s;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: opacity var(--animation-duration) ease, visibility var(--animation-duration) ease;
}

.overlay.active {
  opacity: 1;
  visibility: visible;
}

.sidePanel {
  position: fixed;
  top: 0;
  right: calc(-1 * var(--panel-width));
  width: var(--panel-width);
  height: 100vh;
  background-color: var(--background-secondary);
  border-left: 1px solid var(--border-color);
  z-index: 1001;
  display: flex;
  flex-direction: column;
  transition: right var(--animation-duration) ease;
}

.sidePanel.open {
  right: 0;
}

.header {
  height: var(--header-height);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  border-bottom: 1px solid var(--border-color);
  background-color: var(--background-primary);
}

.title {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  font-family: 'GT-America-Extended-Bold', sans-serif;
  color: var(--text-primary);
  letter-spacing: -0.01em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
  flex: 1;
}

.closeButton {
  background: none;
  border: none;
  color: #FFFFFF;
  cursor: pointer;
  padding: 0;
  margin-left: 12px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none !important;
  outline: none !important;
  border-radius: 0 !important;
  width: auto !important;
  height: auto !important;
  min-width: 0 !important;
  min-height: 0 !important;
  transform: none !important;
  transition: none !important;
  opacity: 1 !important;
}

/* Remove hover effect */
.closeButton:hover,
.closeButton:focus,
.closeButton:active {
  background: none !important;
  color: #FFFFFF !important;
  opacity: 1 !important;
  box-shadow: none !important;
  outline: none !important;
  border: none !important;
  transform: none !important;
}

.content {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
}

@media (max-width: 768px) {
  :root {
    --panel-width: 100vw;
    --header-height: 56px;
  }
  
  .sidePanel {
    box-shadow: none;
  }
  
  .header {
    padding: 0 12px;
  }
  
  .content {
    padding: 16px;
  }
} 