.container {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    background: #1a1c1d;
    border-radius: 8px;
    margin: 16px 0px;
}

.card {
    width: 100%;
    padding: 16px;
    background: #1a1c1d;
    width: 100%;
    padding: 16px;
    flex-direction: column;
    border-radius: 8px;
    gap: 16px;
}

.mentor_info {
    gap: 8px;
    flex-direction: column;
    align-items: flex-start;
}

.mentor_info>h1 {
    margin: 0;
    padding: 0;
    margin-bottom: 4px;
}

.avatar {
    width: 100%;
    height: 100%;
    border-radius: var(--4px, 4px);
}

.session_details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
}

.session_details>div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.session_details_text {
    color: var(--Grey-Text-on-Black, #bfbfbf);
    /* Body / Regular - 12 */
    font-family: "GT America";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.headline {
    color: var(--Grey-Text-on-Black, #bfbfbf);
    font-family: "GT America";
    font-size: 14px;
    margin: 0;
    padding: 0;
    line-height: 1.4;
    margin-bottom: 4px;
}

.details_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--8px, 8px);
    border-top: 1px solid #000;
    padding: 16px;
    width: 100%;
    justify-content: space-between;
}

.agenda {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}

.agenda>p {
    color: #fff;
    /* Body / Regular - 14 */
    font-family: "GT America";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.button_container {
    gap: 8px;
    display: flex;
    width: 100%;
    margin: 16px 0px;
    flex-direction: column;
}

.blue_button {
    display: flex;
    height: 42px;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    width: 100%;
    border-radius: 3px;
    border: 1px solid #fff;
    background: #5440f0;
}

.red_button {
    display: flex;
    height: 42px;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    width: 100%;
    border-radius: 3px;
    background: #ff8571;
}

.black_button {
    display: flex;
    height: 42px;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    width: 100%;
    border-radius: 3px;
    border: 1px solid #fff;
    background: #000;
}

.social_links {
    display: flex;
    gap: 8px;
    margin: 16px 0px;
}

.social_button {
    display: flex;
    width: 32px;
    height: 32px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    border-radius: var(--8px, 8px);
    background: var(--Black, #000);
}

.social_button>* {
    width: 16px;
    height: 16px;
    flex-shrink: 0;
}

.requested_session_action_container {
    display: flex;
    gap: 8px;
    align-items: start;
    justify-content: space-between;
    width: 100%;
    flex-direction: column;
}

.requested_session_button_container {
    display: flex;
    gap: 16px;
    align-items: start;
    justify-content: start;
    width: 100%;
    flex-direction: row;
}

.message_button {
    display: flex;
    height: 42px;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    width: 100%;
    border-radius: 3px;
    background: #5440F0;
    color: white;
    border: none;
    cursor: pointer;
    font-family: "GT America";
    font-size: 14px;
    font-weight: 500;
}

.message_button:hover {
    background: #4535d8;
}

.message_button_container {
    display: flex;
    width: 100%;
    margin-top: 16px;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 900px) {
    .mentor_info>h1 {
        font-size: 32px;
        padding: 0px;
        margin-bottom: 4px;
    }

    .avatar {
        width: 120px;
        height: 120px;
    }

    .card {
        display: flex;
        flex-direction: row;
    }

    .button_container {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 12px;
    }

    .red_button,
    .blue_button,
    .black_button,
    .message_button {
        width: 190px !important;
        flex: 0 0 auto;
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {}