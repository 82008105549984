.breadCrumbs {
	float: left;
}

.follow_btn {
	position: relative;
	margin-bottom: 12px;
}

.action_buttons {
	position: absolute;
	right: 0;
	top: 0;
	display: flex;
	align-items: center;
	gap: 10px;
	z-index: 10;
}

.breadCrumbs > a {
	color: #bfbfbf;
	font-size: 16px;
	line-height: 140%;
	font-family: var(--regular-font);
}

.breadCrumbs > *:not(:first-child) {
	margin-left: 8px;
}

.title {
	color: #fff;
	font-family: var(--bold-font);
}

.profileHeader {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	width: 100%;
}

.headerRight {
	margin-right: 25px;
}

.bio {
	color: #fff;
}

.bio > div {
	color: #fff;
	font-size: 14px;
	line-height: 17px;
	text-align: left;
	margin-top: 25px;
}

.headline {
	font-size: 14px;
	line-height: 17px;
	margin-top: 4px;
	font-family: var(--bold-font);
}
.headerLeft {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.ldh_desktop {
	display: block;
}

.ldh_mobile {
	display: none;
}

.sectionSeparator {
	margin: auto;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	width: 100%;
	background-color: var(--module-bg-dark);
	margin-top: 25px;
	border-radius: var(--corner-radius);
}

.profileIntroduction {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	width: 100%;
	padding: 50px;
}

.profileAbout {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	width: 100%;
	margin: auto;
}

.introTop {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.button {
	width: auto;
	min-width: 140px;
	height: 40px;
	margin-right: 12px;
	margin-bottom: 10px;
	padding: 0 24px;
}

@keyframes messageButtonGlow {
	0% {
		box-shadow: 0 0 0 0 rgba(84, 64, 240, 0);
		border-color: #ffffff;
		text-shadow: none;
	}
	50% {
		box-shadow: 0 0 10px 2px rgba(84, 64, 240, 0.4);
		border-color: rgba(84, 64, 240, 0.8);
		text-shadow: 0 0 8px rgba(255, 255, 255, 0.8);
	}
	100% {
		box-shadow: 0 0 0 0 rgba(84, 64, 240, 0);
		border-color: #ffffff;
		text-shadow: none;
	}
}

/* For Safari */
@-webkit-keyframes messageButtonGlow {
	0% {
		-webkit-box-shadow: 0 0 0 0 rgba(84, 64, 240, 0);
		border-color: #ffffff;
		text-shadow: none;
	}
	50% {
		-webkit-box-shadow: 0 0 10px 2px rgba(84, 64, 240, 0.4);
		border-color: rgba(84, 64, 240, 0.8);
		text-shadow: 0 0 8px rgba(255, 255, 255, 0.8);
	}
	100% {
		-webkit-box-shadow: 0 0 0 0 rgba(84, 64, 240, 0);
		border-color: #ffffff;
		text-shadow: none;
	}
}

.messageButton {
	background-color: transparent;
	border: 1px solid #ffffff;
	color: #ffffff;
	position: relative;
	-webkit-animation: messageButtonGlow 2s ease-in-out 1s 2;
	animation: messageButtonGlow 2s ease-in-out 1s 2;
	transition: all 0.3s ease;
}

.messageButton:hover {
	background-color: #ffffff;
	border: 1px solid #ffffff;
	color: #000000;
	-webkit-animation: none;
	animation: none;
	box-shadow: none;
	text-shadow: none;
}

.messageButton:active {
	background-color: rgba(255, 255, 255, 0.9);
	border: 1px solid #ffffff;
	color: #000000;
	-webkit-animation: none;
	animation: none;
	box-shadow: none;
	text-shadow: none;
}

.messageButtonDisabled {
	background-color: #ffffff;
	border: 1px solid #ffffff;
	color: #000000;
	cursor: not-allowed;
	-webkit-animation: none;
	animation: none;
	box-shadow: none;
	text-shadow: none;
	opacity: 0.5;
}

.messageButtonDisabled:hover {
	background-color: #ffffff;
	border: 1px solid #ffffff;
	color: #000000;
	opacity: 0.5;
}

.stickToBottom {
	bottom: 0px;
	position: absolute;
}

.clipboard {
	background-image: url("../../../../images/mp-background.svg");
	background-color: var(--module-bg-dark);
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
	width: 100%;
	margin-top: 25px;
	border-radius: var(--corner-radius);
}

.spinner {
	height: 100vh;
	width: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.banner {
	color: #fff;
	margin-top: 96px;
	height: 100px;
	width: 100%;
	/* margin-left: 74px; */
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	background-image: url("../../../../images/mp-background.svg");
	background-color: var(--primary-colour);
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}

.banner > h3 {
	text-align: center;
}

.availabilityTabs {
	width: 100%;
	border-radius: var(--corner-radius);
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.chip {
	background-color: var(--black);
	padding: 8px 16px;
	border-radius: var(--corner-radius);
	margin-right: 12px;
	margin-top: 12px;
	width: 136px;
}
.chip > h4 {
	text-align: center;
}

.hasAvailabilityHours {
	background-color: var(--primary-colour);
}

.chip:first-child {
	margin-top: 10px;
}

.notice {
	font-size: 14px;
	color: var(--body-text-on-black);
	margin-top: 16px;
	margin-left: 10px;
}

.reviewItem {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 24px;
	width: 50%;
}

.reviewContainer {
	min-width: 160px;
}

.reviewContainer > p {
	line-height: 24px;
}
.reviewContainer > h4 {
	margin-bottom: 3px;
}

.qualityBox {
	margin-top: 8px;
	display: flex;
	flex-direction: row;
}

.qualityBox > img {
	padding: 8px 12px;
	background: #25282a;
	border-radius: 4px 0px 0px 4px;
	border-right: 2px solid #1a1c1d;
	height: 32px;
}

.qualityBox > p {
	padding: 6px 12px;
	background: #25282a;
	border-radius: 0px 4px 4px 0px;
	color: #fff;
	font-size: 14px;
}

.reviewAvatar {
	background-color: #000;
	border-radius: 50%;
	height: 48px;
	width: 48px;
}

.starIcon {
	width: 16px;
	height: 16px;
	margin-right: 2px;
}

.ratings {
	display: flex;
	margin-top: 8px;
}

.ratings > img {
	width: 18px;
	height: 18px;
}

.ratings > p {
	margin-top: -2px;
	color: #bfbfbf;
	font-size: 16px;
	line-height: 22px;
}

.ratings > p:first-of-type {
	margin-left: 4px;
	margin-right: 3px;
}

.noRating {
	margin-top: -1px;
}

.dot {
	background-color: #bfbfbf;
	border-radius: 50%;
	height: 4px;
	width: 4px;
	margin: 8px 7px 0px 6px;
}

@media (min-width: 900px) {
	.action_buttons {
		position: absolute;
		z-index: 10;
		pointer-events: auto;
	}
	.follow_btn {
		position: absolute;
		pointer-events: auto;
	}
}

@media screen and (max-width: 1180px) and (min-width: 900px) {
	.stickToBottom {
		margin-top: 25px;
		position: relative;
	}
}

@media (max-width: 900px) {
	.ldh_desktop {
		display: none;
	}

	.stickToBottom {
		position: absolute;
	}

	.ldh_mobile {
		display: block;
	}

	.ldh_mobile_credit {
		display: block;
		position: fixed;
		z-index: 9;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
	}

	.ldh_mobile_credit_closed {
		display: block;
		position: fixed;
		z-index: 9;
		bottom: 25px;
		right: 25px;
	}

	.profileIntroduction {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		padding: 20px;
	}

	.introTop {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.sectionSeparator {
		width: 100%;
		border-radius: 0px;
	}

	.profileAbout {
		width: 100%;
	}

	.sectionSeparatorTop {
		width: 100%;
		/* margin-top: 96px; */
		border-radius: 0px;
	}
	.clipboard {
		border-radius: 0px;
	}

	.banner {
		margin-top: 72px;
		width: 100%;
		margin-left: 0px;
	}

	.reviewItem {
		width: 100%;
	}

	.action_buttons {
		position: relative;
		margin-bottom: 15px;
		z-index: 10;
		width: 100%;
		justify-content: flex-end;
	}

	.profile-action-btns {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: flex-start;
		width: 100%;
	}
	
	.button {
		width: auto;
		min-width: 140px;
	}
	
	.messageButton {
		margin-left: 0;
	}
}

.buttonAsAnchor {
	padding: 0px;
	font-family: "GT-America-Standard";
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	height: 14px;
	line-height: 140%;
	color: var(--body-text-on-black);
	text-align: left;
	text-decoration: none;
	cursor: pointer;
	background-color: inherit;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.buttonAsAnchor:hover {
	background-color: inherit;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.editProfileButton {
	position: relative;
	/* left: 12px;
  top: 12px; */
	width: 100%;
	height: 36px;
	margin-bottom: 12px;
}

.editProfileButton {
	display: none;
}

.followButton {
	position: absolute;
}

@media (max-width: 500px) {
	.button {
		width: 100%;
		margin-right: 0;
	}
	.stickToBottom {
		margin-top: 25px;
		position: relative;
	}

	.banner {
		display: none;
	}

	.editProfileButton {
		position: relative;
		/* left: 12px;
    top: 12px; */
		width: 100%;
		height: 36px;
		margin-bottom: 12px;
	}
}
