:root {
  --background-primary: #111213;
  --background-secondary: #1a1b1e;
  --text-primary: #ffffff;
  --text-secondary: #a9adc1;
  --border-color: #2e3036;
  --accent-primary: #5440f0;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  background-color: var(--background-primary);
  border-bottom: 1px solid var(--border-color);
  height: 60px;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 10;
}

.profileSection {
  display: flex;
  align-items: center;
  min-width: 0;
  max-width: calc(100% - 50px);
  overflow: hidden;
  flex: 1;
}

.profileImage {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid var(--border-color);
  flex-shrink: 0;
  margin-right: 12px;
}

.profileName {
  margin: 0;
  font-size: 15px;
  font-weight: 600;
  font-family: 'GT-America-Extended-Bold', sans-serif;
  color: var(--text-primary);
  letter-spacing: -0.01em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.infoButton {
  background: transparent;
  border: none;
  color: var(--text-secondary);
  cursor: pointer;
  padding: 4px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  margin-left: 8px;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
}

.infoButton:hover {
  color: var(--text-primary);
  background-color: rgba(255, 255, 255, 0.05);
}

@media (max-width: 768px) {
  .header {
    padding: 10px 12px;
    height: 52px;
    position: fixed;
    top: 74px; /* Height of the main header */
    left: 0;
    right: 0;
    z-index: 11;
  }
  
  .profileImage {
    width: 28px;
    height: 28px;
  }
  
  .profileName {
    font-size: 14px;
  }
} 