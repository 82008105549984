/* Base skeleton animation */
@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

.conversationsList {
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 6px 0;
}

.conversationCard {
  display: flex;
  align-items: center;
  padding: 12px;
  border-radius: 6px;
  background-color: rgba(40, 40, 40, 0.2);
  margin-bottom: 2px;
}

.avatarSkeleton {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  flex-shrink: 0;
  margin-right: 12px;
  background: linear-gradient(90deg, #232427 25%, #2c2d31 50%, #232427 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}

.contentContainer {
  flex: 1;
  min-width: 0;
  margin-right: 10px;
}

.nameSkeleton {
  height: 14px;
  width: 100px;
  border-radius: 4px;
  margin-bottom: 5px;
  background: linear-gradient(90deg, #232427 25%, #2c2d31 50%, #232427 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}

.messageSkeleton {
  height: 12px;
  width: 90%;
  border-radius: 4px;
  background: linear-gradient(90deg, #232427 25%, #2c2d31 50%, #232427 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
  opacity: 0.7;
}

.timeContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  min-width: 40px;
}

.timeSkeleton {
  height: 10px;
  width: 36px;
  border-radius: 2px;
  background: linear-gradient(90deg, #232427 25%, #2c2d31 50%, #232427 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
  opacity: 0.6;
} 