.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modalContent {
    background-color: #000;
    box-shadow: 0px 0px 15px 5px #3a3a3a;
    padding: 20px;
    border-radius: 8px;
    width: 300px;
    position: relative;
  }
  
  .closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    color: #FFFFFF;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: none !important;
    outline: none !important;
    border-radius: 0 !important;
    width: auto !important;
    height: auto !important;
    min-width: 0 !important;
    min-height: 0 !important;
    transform: none !important;
    transition: none !important;
    opacity: 1 !important;
    font-size: 16px;
  }
  
  /* Add hover state override */
  .closeButton:hover,
  .closeButton:focus,
  .closeButton:active {
    background: none !important;
    color: #FFFFFF !important;
    opacity: 1 !important;
    box-shadow: none !important;
    outline: none !important;
    border: none !important;
    transform: none !important;
  }
  
  .setting {
    margin-top: 15px;
    margin-bottom: 25px;
  }
  
  .saveButton {
    margin-top: 15px;
    background-color: #5440F0;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
  }
  
  .modalTitle{
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .title{
    margin-bottom: 10px;
  }

  .messageInput{
    margin: 0px !important;
    background: #000 !important;
    color: #c4c4c4 !important;
    font-size: 16px !important;
    border: 1px var(--body-text-on-white) solid !important;
  }

  .toggleContainer{
    display: flex;
    align-items: center;
  }

  .toggleButton{
    background-color: white;
    color: #25282A;
    border: none;
    padding: 10px;
    cursor: pointer;
    font-weight: 600;
    width: 50%;
  }

  .toggleButtonSelected{
    background-color: #ffe645;
    color: black;
    border: none;
    padding: 10px;
    cursor: pointer;
    width: 50%;
    font-weight: 600;
  }
  