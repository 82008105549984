.container {
  background-color: #1e1f23;
  border-radius: 12px;
  padding: 24px;
  margin-bottom: 20px;
  border: 1px solid rgba(255, 255, 255, 0.06);
}

.profileHeader {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.profileImage {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 16px;
  border: 2px solid rgba(255, 255, 255, 0.08);
}

.profileInfo {
  flex: 1;
}

.name {
  margin: 0 0 4px 0;
  font-size: 18px;
  font-weight: 600;
  color: #ffffff;
  font-family: 'GT-America-Extended-Bold', sans-serif;
  letter-spacing: -0.01em;
  line-height: 1.2;
}

.headline {
  margin: 0;
  font-size: 14px;
  color: #a9adc1;
  font-family: 'GT-America-Standard', sans-serif;
  line-height: 1.4;
}

.stats {
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid rgba(255, 255, 255, 0.06);
  display: flex;
  justify-content: space-between;
}

.statItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 4px;
  min-width: 0;
}

.statValue {
  font-size: 17px;
  font-weight: 600;
  color: #ffffff;
  font-family: 'GT-America-Standard', sans-serif;
  margin-bottom: 3px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.statLabel {
  font-size: 11px;
  color: #8a8d98;
  font-family: 'GT-America-Standard', sans-serif;
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 0.3px;
  white-space: nowrap;
}

@media (max-width: 768px) {
  .container {
    padding: 18px;
  }
  
  .profileHeader {
    margin-bottom: 16px;
  }
  
  .profileImage {
    width: 48px;
    height: 48px;
  }
  
  .name {
    font-size: 16px;
  }
  
  .stats {
    padding-top: 14px;
    margin-top: 14px;
  }
  
  .statValue {
    font-size: 15px;
  }
  
  .statLabel {
    font-size: 10px;
  }
} 