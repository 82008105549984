.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 1100;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
  backdrop-filter: blur(3px);
  animation: fadeIn 0.15s ease;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.modal {
  background-color: #1a1b1e;
  width: 100%;
  max-width: 480px;
  max-height: 85vh;
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  animation: slideUp 0.2s ease;
}

@keyframes slideUp {
  from { transform: translateY(20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.07);
  background-color: #1e1f23;
}

.title {
  margin: 0;
  font-size: 15px;
  font-weight: 600;
  color: #ffffff;
  font-family: 'GT-America-Extended-Bold', sans-serif;
  letter-spacing: -0.01em;
}

.closeButton {
  background: none;
  border: none;
  color: #FFFFFF;
  cursor: pointer;
  padding: 0;
  margin-left: 12px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none !important;
  outline: none !important;
  border-radius: 0 !important;
  width: auto !important;
  height: auto !important;
  min-width: 0 !important;
  min-height: 0 !important;
  transform: none !important;
  transition: none !important;
  opacity: 1 !important;
}

.closeButton:hover,
.closeButton:focus,
.closeButton:active {
  background: none !important;
  color: #FFFFFF !important;
  opacity: 1 !important;
  box-shadow: none !important;
  outline: none !important;
  border: none !important;
  transform: none !important;
}

.content {
  padding: 20px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.infoSection {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.dateStatus {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.statusBadge {
  display: inline-flex;
  align-items: center;
  padding: 4px 10px;
  border-radius: 6px;
  color: #000;
  font-size: 13px;
  font-weight: 500;
  font-family: 'GT-America-Standard', sans-serif;
  align-self: flex-start;
}

.date {
  font-size: 14px;
  color: #a9adc1;
  font-family: 'GT-America-Standard', sans-serif;
}

.price {
  font-size: 15px;
  font-family: 'GT-America-Standard', sans-serif;
  text-align: right;
}

.label {
  color: #8a8d98;
  font-size: 14px;
  margin-right: 4px;
}

.value {
  color: #15B881;
  font-weight: 500;
}

.section {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-top: 16px;
  border-top: 1px solid rgba(255, 255, 255, 0.07);
}

.sectionTitle {
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  margin: 0;
  font-family: 'GT-America-Extended-Bold', sans-serif;
  letter-spacing: -0.01em;
}

.description {
  font-size: 14px;
  line-height: 1.5;
  color: #a9adc1;
  margin: 0;
  white-space: pre-wrap;
  font-family: 'GT-America-Standard', sans-serif;
}

.reviewHeader {
  display: flex;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
}

.rating {
  display: flex;
  align-items: center;
  gap: 4px;
  background-color: rgba(65, 56, 32, 0.9);
  padding: 5px 10px;
  border-radius: 6px;
}

.star {
  color: #FBC95C;
  font-size: 15px;
}

.ratingValue {
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  font-family: 'GT-America-Standard', sans-serif;
}

.outOf {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  font-family: 'GT-America-Standard', sans-serif;
}

.compliment {
  background-color: rgba(21, 184, 129, 0.15);
  color: #ffffff;
  padding: 5px 10px;
  border-radius: 6px;
  font-size: 13px;
  font-family: 'GT-America-Standard', sans-serif;
}

.feedback {
  margin-top: 8px;
  background-color: #1e1f23;
  padding: 16px;
  border-radius: 8px;
}

.feedbackTitle {
  font-size: 13px;
  color: #8a8d98;
  margin: 0 0 8px 0;
  font-weight: normal;
  font-family: 'GT-America-Standard', sans-serif;
}

.feedbackText {
  font-size: 14px;
  line-height: 1.5;
  color: #ffffff;
  margin: 0;
  font-family: 'GT-America-Standard', sans-serif;
}

@media (max-width: 640px) {
  .overlay {
    padding: 16px;
  }
  
  .modal {
    max-height: 90vh;
  }
  
  .header {
    padding: 14px 16px;
  }
  
  .content {
    padding: 16px;
    gap: 16px;
  }
  
  .infoSection {
    flex-direction: column;
    gap: 16px;
  }
  
  .price {
    text-align: left;
  }
} 