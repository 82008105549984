.send_message_button {
  background-color: #5440F0;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  font-family: "GT-America-Extended-Bold";
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.send_message_button:hover {
  background-color: #4535d8;
}

.send_message_button:disabled {
  background-color: #a9a9a9;
  cursor: not-allowed;
} 