/* Base skeleton animation */
@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

.container {
  margin-bottom: 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.titleSkeleton {
  height: 18px;
  width: 180px;
  border-radius: 4px;
  background: linear-gradient(90deg, #232427 25%, #2c2d31 50%, #232427 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}

.scrollableContainer {
  position: relative;
  display: flex;
  align-items: center;
}

.scrollContainer {
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  width: 100%;
  padding: 4px 0;
}

.scrollContainer::-webkit-scrollbar {
  display: none;
}

.avatarList {
  display: flex;
  gap: 16px;
  padding: 4px 0;
}

.avatarWrapper {
  position: relative;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 48px;
}

.avatarSkeleton {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: linear-gradient(90deg, #232427 25%, #2c2d31 50%, #232427 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}

.nameSkeleton {
  height: 10px;
  width: 38px;
  margin-top: 8px;
  border-radius: 2px;
  background: linear-gradient(90deg, #232427 25%, #2c2d31 50%, #232427 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
} 